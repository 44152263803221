import { BloodNeedPost } from "../types/blood";
import { CommonResponse } from "../types/common";
import { fetcher } from "../utils/fetcher";

export async function createBloodNeedPost(body: BloodNeedPost): Promise<CommonResponse> {
    const authData = JSON.parse(localStorage.getItem('auth') || '');
    const token = authData?.token;
    const url = `${process.env.REACT_APP_API_ORIGIN}/blood-donation/need`;

    const response = await fetcher(url,
        {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        })

    return response;
}