import { getToken, MessagePayload, onMessage } from "firebase/messaging";
import { updateFCMtokenApi } from "../apis/updateFCMtoken";
import { messaging } from "../config/firebase";

async function syncFCMToken() {
    try {
        const fcmtoken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FCM_VAPID_KEY });

        const oldToken = localStorage.getItem('fcmtoken');
        const isFCMSynced = JSON.parse(localStorage.getItem('isFCMSynced') || 'false');

        if (fcmtoken !== oldToken || !isFCMSynced) {
            console.log('Token updated. so sync to database and in local storage');

            localStorage.setItem('fcmtoken', fcmtoken);
            try {
                const updateResult = await updateFCMtokenApi(fcmtoken);
                if (updateResult?.code == 200) {
                    localStorage.setItem('isFCMSynced', "true");
                }
                else {
                    localStorage.setItem('isFCMSynced', "false");
                }
            }
            catch (error) {
                localStorage.setItem('isFCMSynced', "false");
            }
            // setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        }
        else if (!fcmtoken) {
            console.log('No registration token available. Request permission to generate one.');
            // setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }
    catch (error) {
        console.log('An error occurred while retrieving token. ', error);
    }
}

export const onMessageListener = (): Promise<MessagePayload> =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

export const initFCM = () => {
    syncFCMToken().finally(() => console.log('FCM initiated'));
}