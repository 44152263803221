import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/app.context";
import CancelButton from "../utils/CancelButton";
import SaveButton from "../utils/SaveButton";
import { Loader } from "../Loader";
import { BloodNeedPost } from "../../types/blood";
import { useNavigate } from "react-router-dom";
import { createBloodNeedPost } from "../../apis/createBloodNeedPost";

const BloodGroups = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'];

export default function BloodNeed() {
    const appContext = useContext(AppContext);
    const today = new Date().toISOString().split('T')[0];
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        appContext?.setTitle('রক্ত প্রয়োজন');
    }, [])
    const [bloodNeedInfo, setBloodNeedInfo] = useState<BloodNeedPost>({
        bloodGroup: 'A+',
        donationDate: today,
        needer: '',
        note: '',
        quantity: 1,
        place: '',
        phone: '',
    });
    const [err, setErr] = useState<string>('');
    const [inputDisabled, setInputDisabled] = useState(false);
    const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
        try {
            ev.preventDefault();
            setInputDisabled(true);
            setLoading(true);
            const res = await createBloodNeedPost(bloodNeedInfo);

            if (res?.code !== 200) {
                setErr(res?.error || res?.message);
            }
            else {
                navigate('/blood-donation');
            }
        }
        catch (error) {
            console.error(error);
            setErr('Something went wrong');
        }
        finally {
            setInputDisabled(false);
            setLoading(false);
        }
    }
    return (
        <div className="m-2 flex justify-center">
            {loading === true ?
                <Loader />
                : <form
                    action="#"
                    onSubmit={handleSubmit}
                >
                    <p className="text-lg text-center border-b-[1px] mb-3">সঠিকভাবে তথ্য পূরণ করুন</p>
                    <div className="grid grid-cols-2 m-2">
                        <label className="col-span-1" htmlFor="needer"> নাম </label>
                        <input
                            className="col-span-1 border p-1 rounded-lg"
                            value={bloodNeedInfo?.needer}
                            onChange={(ev) => setBloodNeedInfo({ ...bloodNeedInfo, needer: ev.target.value })}
                            required
                            disabled={inputDisabled}
                        />
                    </div>
                    <div className="grid grid-cols-2 m-2">
                        <label className="col-span-1" htmlFor="bloodGroup"> রক্তের গ্রুপ</label>
                        <select
                            className="col-span-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                            onChange={(ev) => setBloodNeedInfo({ ...bloodNeedInfo, bloodGroup: ev.target.value })}
                            defaultValue={bloodNeedInfo?.bloodGroup}
                            required
                            disabled={inputDisabled}
                        >
                            {
                                BloodGroups.map((bGroup) => (
                                    <option
                                        value={bGroup}
                                        key={bGroup}
                                    >
                                        {bGroup}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="grid grid-cols-2 m-2">
                        <label className="col-span-1" htmlFor="quantity"> পরিমান </label>
                        <input
                            className="col-span-1 border p-1 rounded-lg"
                            type="number"
                            value={bloodNeedInfo?.quantity}
                            onChange={(ev) => setBloodNeedInfo({ ...bloodNeedInfo, quantity: Number(ev.target.value) })}
                            required
                            disabled={inputDisabled}
                        />
                    </div>
                    <div className="grid grid-cols-2 m-2">
                        <label className="col-span-1" htmlFor="donationDate"> সময় </label>
                        <input
                            className="col-span-1 border p-1 rounded-lg"
                            type="date"
                            min={today}
                            value={bloodNeedInfo?.donationDate}
                            onChange={(ev) =>
                                setBloodNeedInfo({ ...bloodNeedInfo, donationDate: ev.target.value })
                            }
                            required
                            disabled={inputDisabled}
                        />
                    </div>
                    <div className="grid grid-cols-2 m-2">
                        <label className="col-span-1" htmlFor="place"> স্থান </label>
                        <input
                            className="col-span-1 border p-1 rounded-lg"
                            value={bloodNeedInfo?.place}
                            onChange={(ev) => setBloodNeedInfo({ ...bloodNeedInfo, place: ev.target.value })}
                            required
                            disabled={inputDisabled}
                        />
                    </div>
                    <div className="grid grid-cols-2 m-2">
                        <label className="col-span-1" htmlFor="phone"> ফোন </label>
                        <input
                            type="tel"
                            className="col-span-1 border p-1 rounded-lg"
                            value={bloodNeedInfo?.phone}
                            onChange={(ev) => setBloodNeedInfo({ ...bloodNeedInfo, phone: ev.target.value })}
                            required
                            disabled={inputDisabled}
                        />
                    </div>
                    <div className="grid grid-cols-2 m-2">
                        <label className="col-span-1" htmlFor="note"> নোট </label>
                        <input
                            className="col-span-1 border p-1 rounded-lg"
                            value={bloodNeedInfo?.note}
                            onChange={(ev) => setBloodNeedInfo({ ...bloodNeedInfo, note: ev.target.value })}
                            disabled={inputDisabled}
                        />
                    </div>

                    {
                        err && (
                            <div
                                className="text-red-400 text-sm text-center"> {err}
                            </div>
                        )
                    }
                    <div className="grid grid-cols-2 gap-4 mt-10">
                        <SaveButton onSave={() => handleSubmit} isActive={!inputDisabled} buttonText="পোস্ট করুন" />
                        <CancelButton onCancel={() => { navigate(-1) }} buttonText='বাতিল করুন' />
                    </div>
                </form>}
        </div>
    )
}
