interface Props {
    onCancel: () => void;
    buttonText?: string;
    isActive?: boolean
}

export default function CancelButton(props: Props) {
    return (
        <button
            className="bg-red-400 p-2 flex justify-center w-full rounded-md"
            disabled={props?.isActive === false}
            onClick={(ev: any) => { ev.preventDefault(); props.onCancel(); }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            <p className="text-white mx-2"> {props?.buttonText || "Cancel"} </p>
        </button>
    );
}
