interface Props {
    onSave: () => void;
    customColor?: 'primary' | 'secondary';
    isActive?: boolean;
    buttonText?: string;
}

export default function (props: Props) {
    return (
        <button className={`${props?.customColor === 'secondary' ? 'bg-cyan-400' : 'bg-ittehad-theme'} p-2 flex justify-center w-full rounded-md ${!!props?.isActive === false ? 'bg-slate-300' : ''}`}
            onClick={() => props.onSave()}
            disabled={!props?.isActive}
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22" strokeWidth={1.5} stroke="white" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" fill="" />
            </svg>
            <p className="text-white mx-2"> {props?.buttonText || "Save"} </p>
        </button>
    );
}